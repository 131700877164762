<script>
	export let field;
	export let parent;
	export let app;
	export let form;

	function print() {
		var mywindow = window.open('', 'PRINT', 'height=400,width=600');
		mywindow.document.write('<html><head><title>' + document.title + '</title>');
		mywindow.document.write(Array.from(document.querySelectorAll("head link")).map(function (e) { return e.outerHTML; }).join(""));
		mywindow.document.write('</head><body class="no-print">');
		mywindow.document.write(document.getElementsByClassName('response')[0].innerHTML);
		mywindow.document.write('</body></html>');
		mywindow.document.close(); // necessary for IE >= 10
		mywindow.focus(); // necessary for IE >= 10*/
		setTimeout(function () {
			mywindow.print();
			mywindow.close();
		}, 500);
	}
</script>

<button on:click={() => print()} class="btn btn-success" style="margin-right: 5px;"><i class="fa fa-print"></i></button>

