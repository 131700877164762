<script>
		import { onMount } from "svelte";
	export let field;
	export let id;
	export let tabindex;

	onMount(() => {
		debugger;
		console.log(field)
	});
</script>

{#if field.value != null}

<table class="table table-condensed table-striped table-bordered table-hover">
	<thead>
		<tr>
			<th>
				Id
			</th>
			<th>
				Name
			</th>
			<th>
				Order
			</th>
		</tr>
	</thead>
	<tbody>
		{#if field.value.approvals.length == 0}
		<tr>
			<td colspan="4">
				<div class="text-center alert noresults">
					<span>No results</span>
				</div>
			</td>
		</tr>
		{:else}
		{#each field.value.approvals as item}
		<tr>
			<td>{item.id}</td>
			<td>{item.name}</td>
            <td>
				<input class="form-control"
				style="min-width:125px"

				id="{id}"
				bind:value="{item.value}"
				required="{field.metadata.required}"
				tabindex="{tabindex}"
				type="number" 
    			step="1"
    			min="0" />
    			 
			</td>
		</tr>
		{/each}
		{/if}
	</tbody>
</table>
{/if}