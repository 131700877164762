<script>
	import DateTime from "uimf-svelte3/src/core/ui/outputs/Datetime.svelte";
	import { OutputFieldMetadata } from "uimf-core";
	import { onMount, afterUpdate } from "svelte";
	export let field;
	export let parent;
	export let app;
	export let form;
	export let textarea;
	export let selectedFiles;
	export let id;
	export let tabindex;
	let conversation = null;
	export let uploaderConfig;
	let files = [];
console.log("Log");
    console.log(field);

	afterUpdate(() => {
		if (files != null) addFiles();
	});

	onMount(() => {
		uploaderConfig = field.metadata.getCustomProperty("fileUploaderConfig") || {
			allowMultipleFiles: true,
			allowedFileExtensions: "*",
			useFilerApi: false,
		};

		field.useFilerApi = uploaderConfig.useFilerApi;
	});

	function objectToArray(obj) {
		if (obj) {
			return Array.from(Object.keys(obj), (k) => obj[k]);
		}
		return [];
	}

	function getExtension(filename) {
		return `.${filename.split(".").pop()}`;
	}

	function getAllowedFileExtensions() {
		let allowedExtension = "*";
		if (uploaderConfig && uploaderConfig.allowedFileExtensions) {
			allowedExtension = uploaderConfig.allowedFileExtensions
				.split(",")
				.map((item) => item.trim());
		}
		return allowedExtension;
	}

	function canFileBeAdded(filelist, file, allowedFileExtensions, app) {
		const isFileExtensionAllowed =
			allowedFileExtensions.indexOf("*") > -1 ||
			allowedFileExtensions.indexOf(getExtension(file.name)) > -1;

		const fileWasAlreadyAdded = filelist.find((f) => f.name === file.name);

		if (isFileExtensionAllowed && !fileWasAlreadyAdded) {
			return true;
		}

		if (!isFileExtensionAllowed) {
			app.showError(
				`File ${file.name} extension not allowed. Only ` +
					`these file extensions are allowed: ${allowedFileExtensions}.`
			);
		}
		return false;
	}

	export function addFiles(files) {
		const filesToAdd = objectToArray(files);
		let listedFiles = [];

		if (selectedFiles) {
			listedFiles = selectedFiles;
		}

		const allowedFileExtensions = getAllowedFileExtensions();
		const filesThatCanBeAdded = filesToAdd.filter((file) =>
			canFileBeAdded(listedFiles, file, allowedFileExtensions, app)
		);

		if (filesThatCanBeAdded.length > 0) {
			if (!uploaderConfig.allowMultipleFiles) {
				selectedFiles = filesThatCanBeAdded.slice(0, 1);
			} else {
				selectedFiles = filesThatCanBeAdded.concat(listedFiles);
			}
		}

		field.selected = selectedFiles;
	}

	function removeFile(file) {
		let index = files.indexOf(file);
		selectedFiles.splice(index, 1);
		files = selectedFiles;

		if (uploaderConfig != null && !uploaderConfig.allowMultipleFiles) {
			files = null;
		}
		return load();
	}

	function autoExpand() {
		var element = document.getElementById("textarea");
		var newHeight = Math.max(element.offsetHeight, element.scrollHeight);
		element.style.height = newHeight + "px";
	}
	function load() {
		app.server
			.postForm("conversations", { key: field.data.key })
			.then((data) => {
				field.data = data.conversation;
			});
	}
	function postComment(text, key) {
 
		if (confirm("Are you sure you want to post the comment?")) {
			var loader = document.getElementById("AjaxLoader");
			loader.style.display = 'block';
			let filesIds = { items: [] };
 
			if(selectedFiles != null){
				startUpload(selectedFiles).then((data) => {
								filesIds.items = data.files;

								app.server
									.postForm("add-comment", { key: key, Text: text, filesIds: filesIds })
									.then((data) => {
										loader.style.display= 'none';
										files = [];
										return load();
										
									});
							});
			}else{
				app.server.postForm("add-comment", { key: key, Text: text, filesIds: filesIds })
									.then((data) => {
										loader.style.display= 'none';
										files = [];
										return load();
										
									});

			}
			
		}
	}

	function deleteComment(commentId) {
		if (confirm("Are you sure you want to delete the comment?")) {
			app.server.postForm("delete-comment", { Id: commentId }).then((data) => {
				return load();
			});
		}
	}
	function deleteCommentAttachment(fileId,commentId) {
		console.log("field"+fileId+"##Comment:"+commentId);
		if (confirm("Are you sure you want to delete the attachment?")) {
			app.server.postForm("delete-comment-attachment", { Id: fileId, CommentID:commentId })
			.then((data) => {
				return load();
			});
		}
	}
	
	function startUpload(files) {
		var promises = [];
		var uploaded = [];
		if (files) {
			let formData = new FormData();
			for (const f of files) {
				formData.append("file", f);
			}
			promises.push(
				new Promise((resolve, reject) => {
					// Make http request to upload the files.
					fetch("/document/UploadDocument", {
						method: "POST",
						body: formData,
					})
						.then((res) => res.json())
						.then((response) => {
							if (response.Exception != null) {
								alert(response.Message);
								reject(response.Exception);
							}
							if (response != null) {
								for (const id of response.fileIds) {
									uploaded.push(id);
								}
							}
							resolve();
						})
						.catch((error) => {
							alert(error.response.data.Message);
							reject(error);
						});
				})
			);

			files = [];
			return Promise.all(promises).then(() => {
				return {
					files: uploaded,
				};
			});
		} else {
			files = [];
			return Promise.all(promises);
		}
	}

	function FileChanged(e) {
		addFiles(e.target.files);
		files = objectToArray(selectedFiles);
	}
</script>

{#if field.data != null}
	<div class="ConversationBox">
		<h4>Conversation</h4>
		<div>
			{#each field.data.comments as comment}
				<div class="MessageBox">
					<div class="ConversationMsg">
						{comment.text}

					 	{#if comment.canDelete} 
							<button
								class="btn-link"
								on:click={() => deleteComment(comment.id)}
								disabled={!comment.canDelete}
							>
								<span class="fa fa-times text-danger" />
							</button>
					 	{/if} 
					</div>

					<div class="panel-heading">
						<div class="ConversationAuthorBox">
							<span class="ConversationDate"
								><DateTime
									{app}
									{form}
									{parent}
									field={{
										data: comment.postedOn,
										metadata: new OutputFieldMetadata({ customProperties: {} }),
									}}
								/></span
							>

							<span class="AuthorNaneLbL"> by {comment.author} </span>
						</div>
						{#if comment.attachments != null}
							{#each comment.attachments as attached}
								
									<div class="AttachedFileTemp">
										<button
										class="btn-link"
										style="float:right"
										on:click={()=> deleteCommentAttachment(attached.id,comment.id)}
										disabled={!comment.canDelete}
										>
										 
										<span class="fa fa-times" style="color:#888;" />
										</button>
										<a target="_blank" href={attached.name.url}>
											<img src={attached.name.url} alt={attached.name.anchor} /></a>
										<p>{attached.name.anchor}</p>
									</div>
							{/each}
						{/if}
					</div>
				</div>
			{/each}
		</div>

		<div>
			{#if field.data.canAddComments}
				<textarea
					id="textarea"
					required="required"
					class="form-control comment-textarea"
					bind:this={textarea}
					placeholder="Start Conversation ...."
					on:keyup={() => autoExpand()}
				/>
				
				<button
					class="btn btnSendMsg"
					on:click={() => {
						postComment(textarea.value, field.data.key);
						textarea.value = null;
					}}
					disabled={!field.data.canAddComments}
				>
					Send a message
				</button>
				<label class="attachmentLbl">
					<i aria-hidden="true" class="fa fa-paperclip" />

					<input
						multiple
						bind:files
						type="file"
						{id}
						on:change={(e) => FileChanged(e)}
						style="display:none;"
						disabled={!field.data.canAddComments}
					/>
				</label>
				<img id="AjaxLoader" class="Ajax-loader" src="/Content/ajax-loader.gif" alt="loading">
				{#if files != null}
					{#each files as file}
						<div class="AttachedFileTemp">
							{file.name}
							<button
								class="btn-link"
								style="float:right"
								on:click={() => removeFile(file)}
								disabled={!field.data.canAddComments}
								><span class="fa fa-times" style="color:#888; " />
							</button>
						</div>
					{/each}
				{/if}
			{/if}
		</div>
	</div>
{/if}
