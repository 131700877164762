<script>
	export let field;
	export let parent;
	export let app;
	export let form;

	const { data } = field;

	for (const userCalendar of data.userCalendars) {
		for (const log of userCalendar.log) {
			log.date = new Date(log.date);
		}
	}

	const dayCount = daysInMonth(data.year, data.month);
	const calendarDays = new Array(dayCount);

	for (let day = 1; day <= dayCount; day++) {
		const logEntries = {};

		for (const userCalendar of data.userCalendars) {
			logEntries[userCalendar.userName] = userCalendar.log.filter(
				(t) => t.date.getDate() === day && t.date.getMonth()+1 === data.month && t.date.getFullYear() === data.year			
			);
		}

		calendarDays[day - 1] = {
			entries: logEntries,
			date: day,
			dayName: getDayName(data.year, data.month, day),
		};
	}

	function getStyle(entry) {
		let style = "";

		if (entry.isLeave) {
			style += `border-color: ${stringToColor(entry.activity, 0.5)};`;
		} else {
			const bg = stringToColor(entry.activity, 0.5);
			style += `background-color: ${bg}; color: ${contrastColor(bg)};`;
		}

		return style;
	}

	function getDayReportLink(app, date, userId) {
		if (app.getForm("day-report") != null) {
			return app.makeUrl("day-report", {
				Day: date,
				User: { value: userId },
			});
		}
		return "#";
	}

	function daysInMonth(year, month) {
		return new Date(year, month, 0).getDate();
	}

	function getDayName(year, month, day) {
		const days = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
		const dayOfWeek = new Date(year, month - 1, day).getDay();

		return days[dayOfWeek];
	}

	function stringToColor(str) {
		let hash = 0;
		for (let i = 0; i < str.length; i++) {
			hash = str.charCodeAt(i) + ((hash << 5) - hash);
		}
		let colour = "#";
		for (let i = 0; i < 3; i++) {
			const value = (hash >> (i * 8)) & 0xff;
			colour += ("00" + value.toString(16)).substr(-2);
		}
		return colour;
	}

	function shadeColor2(color, percent) {
		const f = parseInt(color.slice(1), 16);
		const t = percent < 0 ? 0 : 255;
		const p = percent < 0 ? percent * -1 : percent;
		const R = f >> 16;
		const G = (f >> 8) & 0x00f;
		const B = f & 0x0000ff;

		return (
			"#" +
			(
				0x1000000 +
				(Math.round((t - R) * p) + R) * 0x10000 +
				(Math.round((t - G) * p) + G) * 0x100 +
				(Math.round((t - B) * p) + B)
			)
				.toString(16)
				.slice(1)
		);
	}

	function contrastColor(color) {
		const r = parseInt(color.substr(1, 2), 16);
		const g = parseInt(color.substr(3, 2), 16);
		const b = parseInt(color.substr(5, 2), 16);

		// Counting the perceptive luminance - human eye favors green color...
		const a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;

		if (a < 0.5) {
			return "#000";
		} else {
			return "#fff";
		}
	}
</script>
{#if field.data != null}
	<h4>Calendar for {field.data.month}/{field.data.year}</h4>
	<dl class="status-legend">
		{#each field.data.legends as legend}
			<dt class="{legend.cssClass}"></dt>
			<dd>{legend.status}</dd>
		{/each}
	</dl>
	{#if calendarDays != null}
		<table class="table">
			<thead>
				<tr>
					<th />
					{#each calendarDays as calendarDay}
						<th class="day-number">{calendarDay.date}</th>
					{/each}
				</tr>
				<tr>
					<th />
					{#each calendarDays as calendarDay}
						<th class="day-name">{calendarDay.dayName}</th>
					{/each}
				</tr>
			</thead>

			<tbody>
				{#each field.data.userCalendars as userCalendar}
					<tr>
						<td>{userCalendar.userName}</td>

						{#each calendarDays as calendarDay}
							<td class={calendarDay.dayName == "Fr" ? "weekend" : ""}>
								{#each calendarDay.entries[userCalendar.userName] as entry}
									<a
										href={getDayReportLink(
											app,
											entry.date,
											userCalendar.userId
										)}
										class="entry {entry.class} {entry.isLeave
											? 'leave'
											: 'activity'}"
										data-tooltip={entry.activity}
										style="{getStyle(entry)};"
									>
										{entry.tag}
									</a>
								{/each}
							</td>
						{/each}
					</tr>
				{/each}
			</tbody>
		</table>
	{/if}
{/if}
