import * as umf from "../../../../node_modules/uimf-svelte3/src/core/framework/index";

export class OrderApprovalInputController extends umf.InputController<OrderApproval> {

	serializeValue(value: OrderApproval | string): any {
		debugger;
		var parsed = this.parse(value);
		return JSON.stringify(parsed);
	}

	init(value: any): Promise<OrderApprovalInputController> {
		return new Promise((resolve, reject) => {			
			this.value = this.parse(value);
			resolve(this);
		});
	}

	getValue(): Promise<OrderApproval> {
		var value = this.parse(this.value);
		var promises = [];

		return Promise.all(promises).then(() => {
			return value;
		});
	}

	private parse(value: OrderApproval | string): OrderApproval {
		debugger;
		if (value == null) {
			return new OrderApproval();
		}

		var parsed = typeof(value) === "string" 
			? JSON.parse(value)
			: parsed = value;

		return parsed == null || typeof(parsed.approvals) === "undefined"
			? new OrderApproval()
			: parsed;
	}
}
// tslint:disable-next-line:max-classes-per-file
class OrderApproval {
	constructor(approvals: Approval[] = []) {
		this.approvals = approvals;
	}

	approvals: Approval[] = [];
}


class Approval{
	public name: string;
	public value: number;
	public id: number;
}